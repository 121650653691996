/*
create EvOccMappingComponen standalone, hope this can be easily use in somewhere else
  notes : uses micro service data modal
          perhas need a util convert/mapping from micro to event form data model in the future use
*/

import {
    Component,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef,
    ViewEncapsulation,
    OnInit,
    signal,
    Output,
    EventEmitter,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { OccurrenceI } from "../pojo/OccurrenceI";
import { S25Util } from "../util/s25-util";
import { S25Reservation } from "../modules/s25-event/ReservationI";
import { S25ItemI } from "../pojo/S25ItemI";
import { EventService } from "../services/event.service";
import { S25ReservationUtil } from "../modules/s25-event/utils/s25.reservation.util";
import { UserprefService } from "../services/userpref.service";
import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";
import { dataI } from "../modules/s25-event/s25-event-occurrences/ev-occ-menu/ev-occ-menu.component";
import { S25EditableModule } from "../modules/s25-editable/s25.editable.module";
import { S25IconModule } from "../modules/s25-icon/s25.icon.module";
import { S25ItemModule } from "../modules/s25-item/s25.item.module";

interface Reservation {
    date: string;
    start: string;
    end: string;
    resources: S25ItemI[];
    locations: S25ItemI[];
}

@Component({
    standalone: true,
    imports: [S25EditableModule, S25IconModule, S25ItemModule, FormsModule, CommonModule],
    selector: "s25-ng-occ-mapping",
    template: `@if (init) {
        <div class="c-margin-bottom--single c-margin-top--single" aria-label="Copy Segment Occurrences" tabindex="0">
            <div class="copy-segment-container">
                <section class="input-data">
                    <label class="c-margin-bottom--quarter c-margin-top--half" for="segmentName"> Segment Name</label>
                    @if (profileName()) {
                        <s25-ng-editable-text
                            class="segmentName"
                            [allowEmpty]="false"
                            [min]="2"
                            [max]="40"
                            [alwaysEditing]="true"
                            [val]="profileName()"
                            (valChange)="profileName.set($event)"
                        ></s25-ng-editable-text>
                    }
                    <label class="c-margin-bottom--quarter c-margin-top--half" for="startDateContainer"
                        >Start Date</label
                    >
                    <s25-ng-editable-date [val]="startDate" (valChange)="updateStartDate($event)">
                    </s25-ng-editable-date>

                    <label class="c-margin-top--half" for="occurrenceDetails"> Occurrence Mapping </label>
                    <select
                        class="ngListPageDropdown"
                        [(ngModel)]="mapping"
                        (ngModelChange)="updateMapping()"
                        id="occurrenceDetails"
                        name="occurrenceDetails"
                    >
                        <option value="one-by-one">One-to-one</option>
                        <option value="date-by-date">Date-to-date</option>
                        <option value="none">No Mapping</option>
                    </select>
                </section>

                <div class="divider"></div>
                <section class="occurrence-view">
                    @for (res of reservations; track res.date + "-" + i; let i = $index) {
                        <div class="original-occurrence" [ngClass]="{ 'date-mapped': mapping === 'date-by-date' }">
                            <div class="occurrence-date" [ngClass]="{ mapped: showMapping }">
                                <div>{{ res.date }}</div>
                                @if (showMapping && mapping !== "date-by-date") {
                                    <s25-ng-icon [type]="'arrowRightDown'"> </s25-ng-icon>
                                }
                                @if (showMapping && mapping !== "date-by-date") {
                                    <span [ngClass]="{ 'multi-line': isMultipleDays }">{{
                                        newReservations[i].date
                                    }}</span>
                                }
                            </div>
                            <div class="occurrence-times">
                                <div>{{ res.start }}</div>
                                <div>{{ res.end }}</div>
                            </div>
                            <div class="occurrence-objects">
                                @if (mapping === "one-by-one" || mapping === "date-by-date") {
                                    @if (!res.locations && !res.resources) {
                                        <div class="no-details">No Additional Details Found</div>
                                    }
                                    @for (location of res.locations; track location.itemId + "-" + i; let i = $index) {
                                        <div>
                                            <s25-item-space
                                                [modelBean]="{ itemId: location.itemId }"
                                                [includeTypeIcon]="true"
                                            ></s25-item-space>
                                        </div>
                                    }
                                    @for (resource of res.resources; track resource.itemId + "-" + i; let i = $index) {
                                        <div>
                                            <s25-item-resource
                                                [modelBean]="{ itemId: resource.itemId }"
                                                [includeTypeIcon]="true"
                                            ></s25-item-resource>
                                        </div>
                                    }
                                }
                                @if (mapping === "none") {
                                    @if (!reservations[0].locations && !reservations[0].resources) {
                                        <div class="no-details">No Additional Details Found</div>
                                    }
                                    @for (location of allLocations; track location.itemId + "-" + i; let i = $index) {
                                        <div>
                                            <s25-item-space
                                                [modelBean]="{ itemId: location.itemId }"
                                                [includeTypeIcon]="true"
                                            ></s25-item-space>
                                        </div>
                                    }
                                    @for (resource of allResources; track resource.itemId + "-" + i; let i = $index) {
                                        <div>
                                            <s25-item-resource
                                                [modelBean]="{ itemId: resource.itemId }"
                                                [includeTypeIcon]="true"
                                            ></s25-item-resource>
                                        </div>
                                    }
                                }
                            </div>
                            @if (showMapping && mapping === "date-by-date") {
                                <div class="mapped-occurrence">
                                    <s25-ng-icon [type]="'arrowRightDown'"></s25-ng-icon>

                                    <div class="occurrence-date">{{ newReservations[i].date }}</div>
                                    <div class="occurrence-times">
                                        <div>{{ newReservations[i].start }}</div>
                                        <div>{{ newReservations[i].end }}</div>
                                    </div>
                                    <div
                                        class="occurrence-objects"
                                        [ngClass]="{
                                            'no-details':
                                                !newReservations[i].locations && !newReservations[i].resources,
                                        }"
                                    >
                                        @if (!newReservations[i].locations && !newReservations[i].resources) {
                                            <div class="no-details">No Additional Details Found</div>
                                        }
                                        @for (
                                            location of newReservations[i].locations;
                                            track location.itemId + "-" + i;
                                            let i = $index
                                        ) {
                                            <div>
                                                <s25-item-space
                                                    [modelBean]="{ itemId: location.itemId }"
                                                    [includeTypeIcon]="true"
                                                ></s25-item-space>
                                            </div>
                                        }
                                        @for (
                                            resource of newReservations[i].resources;
                                            track resource.itemId + "-" + i;
                                            let i = $index
                                        ) {
                                            <div>
                                                <s25-item-resource
                                                    [modelBean]="{ itemId: resource.itemId }"
                                                    [includeTypeIcon]="true"
                                                ></s25-item-resource>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </section>
            </div>
            <button class="aw-button aw-button--outline c-margin-top--half" (click)="onSubmit()">Submit</button>
        </div>
    } `,
    styles: [
        `
            .copy-segment-container {
                display: flex;
                font-size: 0.9rem;
                font-weight: 400;
            }

            .input-data {
                display: flex;
                flex-direction: column;
                padding-right: 20px;
            }

            .divider {
                border-left: 1px solid #e5e5e5;
            }

            .start-date-container {
                display: flex;
                flex-direction: column;
            }

            .occurrence-view {
                padding-right: 20px;
                max-width: 55em;
                width: fit-content;
                height: fit-content;
                max-height: 26em;
                overflow-y: auto;
                box-shadow:
                    rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
                    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
            }

            .original-occurrence {
                padding: 10px;
            }

            .original-occurrence:nth-of-type(even) {
                background-color: rgba(230, 230, 250, 0.5);
            }

            .original-occurrence:not(:last-child) {
                border-bottom: 1px solid #d3d3d3;
            }

            .original-occurrence,
            .mapped-occurrence {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 2em;
            }

            .occurrence-date {
                width: 10em;
            }

            .mapped .occurrence-date {
                position: relative;
                top: 5px;
                width: 15em;
            }

            .mapped .occurrence-date div {
                width: 10em;
            }

            .mapped .occurrence-date span {
                position: relative;
                display: inline-block;
                width: 9em;
            }

            .mapped .occurrence-date span:not(.multi-line) {
                top: 4px;
            }

            .mapped .occurrence-date span.multi-line {
                top: 13px;
            }

            .mapped .multi-line {
                top: 13px;
            }

            .c-svgIcon {
                height: 3em;
                width: 2em;
            }

            .occurrence-objects .no-details {
                position: relative;
                left: 1em;
                font-size: 18px;
                color: #6c6c6c;
            }

            .original-occurrence.date-mapped {
                gap: 1.8em;
            }

            .mapped-occurrence {
                flex-basis: 100%;
                gap: 1em;
            }

            .mapped-occurrence .occurrence-date {
                width: 9em;
            }

            .mapped-occurrence .c-svgIcon {
                position: relative;
                height: 3em;
                width: 2em;
                bottom: 5px;
            }

            :host ::ng-deep .s25-item-holder {
                align-items: flex-start;
                gap: 5px;
                display: flex; /* Use flexbox to arrange child elements in a row */
                flex-wrap: nowrap; /* Prevent wrapping of elements */
            }

            :host ::ng-deep .s25-item-holder .s25-item-label {
                max-width: 21em;
                margin-left: 10px;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvOccMappingComponent implements OnInit {
    @Input() startDate: Date;
    @Input() rsrvs: S25Reservation[];
    @Input() soruceProfileCode?: string;
    @Output() modelValueChange: EventEmitter<dataI> = new EventEmitter<dataI>();

    init: boolean = false;
    showMapping: boolean = true;
    profileName = signal<string>("");
    newProfileId = signal<number>(0);
    mapping: OccurrenceI.Mapping = "one-by-one";
    diffDays: number = 0;
    newRsrvs: S25Reservation[] = [];
    allLocations: S25ItemI[] = [];
    allResources: S25ItemI[] = [];
    newReservations: Reservation[] = []; // temporary  rsrv based on current mapping/date selections for displaying on visual aid
    reservations: Reservation[] = []; //   original rsrv
    dateFormat: string;
    timeFormat: string;
    isMultipleDays: boolean;

    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        await this.loadPreferences();
        await this.setupProfile();
        await this.loadReservations();
        this.init = true;
        this.cd.detectChanges();
    }

    async loadPreferences() {
        const [dateFormat, timeFormat] = await Promise.all([
            UserprefService.getS25Dateformat(),
            UserprefService.getS25Timeformat(),
        ]);
        this.dateFormat = dateFormat;
        this.timeFormat = timeFormat;
    }

    async setupProfile() {
        const profileId = await EventService.getNewProfileId();
        this.newProfileId.set(profileId);
        this.profileName.set("Segment_" + profileId);
    }

    async loadReservations() {
        this.rsrvs.sort((a, b) => (a.eventStart as Date).getTime() - (b.eventEnd as Date).getTime());
        this.diffDays = S25Util.date.diffDays(this.rsrvs[0].eventStart, this.startDate);
        await this.getNewRsrvs();
        const getAllObjs = await S25ReservationUtil.getRsrvsObjects(this.rsrvs, true, false);
        this.allLocations = getAllObjs?.allLocations as S25ItemI[];
        this.allResources = getAllObjs?.allResources as S25ItemI[];
    }

    async updateStartDate(startDate: Date) {
        this.startDate = startDate;
        this.diffDays = S25Util.date.diffDays(this.rsrvs[0].eventStart, startDate);
        this.diffDays !== 0 ? (this.showMapping = true) : (this.showMapping = false);
        await this.getNewRsrvs();
        this.cd.detectChanges();
    }

    updateMapping() {
        this.getNewRsrvs();
        this.cd.detectChanges();
    }

    async getNewRsrvs() {
        this.newRsrvs = await S25ReservationUtil.duplicateReservations(
            this.rsrvs,
            this.diffDays,
            this.mapping,
            this.soruceProfileCode,
        );
        this.reservations = await this.getDisplayData(this.rsrvs);
        this.newReservations = await this.getDisplayData(this.newRsrvs);
    }

    async getDisplayData(data: S25Reservation[]): Promise<Reservation[]> {
        const results: Reservation[] = [];
        data.forEach((res: S25Reservation, i: number) => {
            const isMultipleDays =
                S25Datefilter.transform(res.eventStart, this.dateFormat) !==
                S25Datefilter.transform(res.eventEnd, this.dateFormat);
            const dateRange =
                isMultipleDays &&
                `${S25Datefilter.transform(res.eventStart, this.dateFormat)} - ${S25Datefilter.transform(
                    res.eventEnd,
                    this.dateFormat,
                )}`;
            this.isMultipleDays = isMultipleDays;
            const [resources, locations] = [
                S25ReservationUtil.getObjectsFlat(res.resources[0]) || [],
                S25ReservationUtil.getObjectsFlat(res.locations[0]) || [],
            ];
            results.push({
                date: dateRange || (S25Datefilter.transform(res.eventStart, this.dateFormat) as string),
                start: S25Datefilter.transform(res.eventStart, this.timeFormat) as string,
                end: S25Datefilter.transform(res.eventEnd, this.timeFormat) as string,
                resources: this.mapping !== "none" ? resources : this.allResources,
                locations: this.mapping !== "none" ? locations : this.allLocations,
            });
        });

        return results;
    }

    onSubmit() {
        //occ inline copy segment data, if use somewhere might need to ajust the emit data
        const data: dataI = {
            profileName: this.profileName(),
            profileId: this.newProfileId(),
            newCopyRsrvsData: this.newRsrvs,
        };
        this.modelValueChange.emit(data);
    }
}
