import { Directive, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
    selector: "[s25IfShow]",
    standalone: true,
})
export class S25IfShowDirective implements OnInit {
    private initialized = false;
    private initialDisplay: string;
    private nativeElement: HTMLElement;
    private show = false;

    @Input() set s25IfShow(show: boolean) {
        this.show = show;
        this.displayTemplate();
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2,
    ) {}

    ngOnInit(): void {
        this.displayTemplate();
    }

    private displayTemplate() {
        if (!this.initialized) {
            this.viewContainerRef.clear();
            if (this.show) {
                this.initialized = true;
                const embeddedRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
                this.nativeElement = embeddedRef.rootNodes[0];
                this.initialDisplay = this.nativeElement?.style?.display ?? "";
            }
        } else {
            this.renderer.setStyle(this.nativeElement, "display", this.show ? this.initialDisplay : "none");
        }
    }
}
