import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { S25ButtonComponent } from "./s25.button.component";

@Component({
    selector: `s25-ng-tab-group`,
    template: `
        @for (tab of tabs; track tab.id) {
            <s25-ng-button
                [type]="'none'"
                (click)="chooseTab(tab)"
                [class.active]="tab.id === chosen.id"
                class="tabButton"
            >
                <div class="label">{{ tab.label }}</div>
            </s25-ng-button>
        }
    `,
    styles: `
        :host {
            --active-bg: #2573a7;
            --active-color: white;
            --active-hover-bg: #1e5f94;
            --active-hover-color: white;
            --inactive-bg: white;
            --inactive-color: black;
            --inactive-hover-bg: #f5f6f7;
            --inactive-hover-color: #2573a7;
            --border-radius: 3px;
            --border-width: 1px;
            --border-style: solid;
            --border-color: rgba(0, 0, 0, 0.15);
            --border-color-active: rgba(0, 0, 0, 0.15);

            display: flex;
            flex-wrap: wrap;
            font-size: 1.25em;

            --border: var(--border-width) var(--border-style) var(--border-color);
        }

        ::ng-deep .nm-party--on s25-ng-tab-group {
            --active-bg: #277abe;
            --active-color: white;
            --active-hover-bg: #1e5f94;
            --inactive-bg: #313036;
            --inactive-color: white;
            --inactive-hover-bg: #28272c;
            --inactive-hover-color: #73b0e2;
            --border-color: rgba(255, 255, 255, 0.24);
            --border-color-active: rgba(255, 255, 255, 0.24);
        }

        .tabButton:not(.active) {
            --hover-bg: var(--inactive-hover-bg);
            --hover-color: var(--inactive-hover-color);
            --color: var(--inactive-color);
        }

        .tabButton.active {
            --hover-bg: var(--active-hover-bg);
            --hover-color: var(--active-hover-color);
            --color: var(--active-color);
            z-index: 1;
        }

        .tabButton:first-child .label {
            border-top-left-radius: var(--border-radius);
            border-bottom-left-radius: var(--border-radius);
        }

        .tabButton:not(:first-child) .label {
            margin-left: calc(0px - var(--border-width));
        }

        .tabButton:last-child .label {
            border-top-right-radius: var(--border-radius);
            border-bottom-right-radius: var(--border-radius);
            border-right: var(--border);
        }

        .active .label {
            background: var(--active-bg);
            z-index: 1;
            border: var(--border-width) var(--border-style) var(--border-color-active);
        }

        .label {
            padding: 0.5rem 1rem;
            background: var(--inactive-bg);
            border: var(--border);
            border-right: none;
            color: var(--color);
        }

        .tabButton:focus-within {
            z-index: 2;
        }

        .tabButton:hover .label {
            background: var(--hover-bg);
            color: var(--hover-color);
        }
    `,
    standalone: true,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [S25ButtonComponent],
})
export class S25TabGroupComponent {
    @Input({ required: true }) tabs: TabGroupTab[];
    @Input() chosen: TabGroupTab;
    @Output() chosenChange = new EventEmitter<TabGroupTab>();

    chooseTab(tab: TabGroupTab) {
        this.chosen = tab;
        this.chosenChange.emit(this.chosen);
    }
}

export type TabGroupTab = { id: string | number; label: string };
